@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@200&display=swap');

html, body {
  margin: 0;
  padding: 0;
  font-family: "Work Sans", Helvetica, Arial, sans-serif;
  font-size: 22px;
  line-height: 150%;
  color: #1f1f1f;
  height: 100%;
  display: initial;
  background: #efefef;
}

img {
  max-width: 100%;
}

$primary: #211426;
$primary-hover: #483d67;
$primaryDark: #150e2a;
$primaryLight: #63558e;

a {
  text-decoration: none;
  color: $primary;
  font-weight: bold;
  &:active, &:visited, &:focus {
    color: $primary;
  }
  &:hover {
    color: $primary-hover;
  }
}

.logo {
  width: 300px;
  height: auto;
  margin-bottom: -1.5rem;
}
.white-bg {
  background-color: #ffffff;
}
.accent-bg,
.dark-bg,
.gradient-bg {
  color: #ffffff;
}
.accent-bg {
  background-color: #63558e;
}
.dark-bg {
  background-color: #1f1f1f;
}
.gradient-bg {
  background: rgb(21,14,42);
  background: linear-gradient(180deg, #150e2a 0%, #483d67 100%);
}

.project {
  .logo {
    display: block;
    margin: 0 auto 1rem;
  }
  .description {
    font-size: 0.8rem;
  }
}

form {
  width: 350px;
  margin: 0 auto;
  text-align: left;

  input, textarea {
    display: block;
    width: 100%;
    font-family: inherit;
    font-size: 0.8rem;
    line-height: 150%;
    color: #444444;
    padding: 0.25rem 0.5rem;
    background: white;
    box-shadow: none;
    border: 2px solid $primary;

    &:focus {
      outline: $primaryLight;
      border-color: $primaryLight;
    }
  }

  label {
    display: block;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;  
    margin-bottom: 0.5rem;
  }

  button {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    font-weight: bold;
    font-family: inherit;
    color: #ffffff;
    text-transform: uppercase;
    background: $primary;
    border: none;
    transition: all 0.5s;
    &:hover {
      background: $primary-hover;
      cursor: pointer;
    }
  }
}